.productItem{
    border-radius: 0;
    display: flex;
    min-height: max-content;
    max-height: max-content;
    
}
.displayType{
    font-size:25px;
    color:#555555
}
.activeDisplayType{
    background-color:#f5f5f5
}

.productItemButtons{
    display: none;
}

.backgroundCard{
    display: none;

}








@media (min-width: 1200px) {
    .col-xl-15 {
        width: 20% !important;
        float: left !important;
    }
}

@media (min-width:1000px){
 
    
    .productItem:hover .backgroundCard .productItemButtons{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    

}