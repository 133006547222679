#sub-menu{
    background-color: white;
}
.my-container {
    
}

@media (min-width: 1000px) {
    #sub-menu {
        display: none;
    }
}

@media (min-width: 978px) {
    .my-container {
    padding-left:12px;
    padding-right:12px;
    margin:0;
    }

    body {
      padding:0;
    }

    .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
      margin-left: 0;
      margin-right: 0;
      margin-bottom:0;
    }
}